<template>
  <div>
    <div class="resume-content">
      <div class="section border-bottom">
        <el-row type="flex">
          <el-col>
            <el-row type="flex" justify="space-between" align="middle">
              <el-col :span="12">
                <h1>
                  李连琦
                  <span style="font-size: 0.7em; color: rgba(51, 203, 255, 0.48);">
                    <i class="el-icon-male"></i>
                  </span>
                </h1>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="8">
                <p>
                  <i class="el-icon-sugar"></i>
                  {{workAge}}工作经验
                </p>
              </el-col>
              <el-col :span="8">
                <p>
                  <i class="el-icon-medal-1"></i>
                  本科学历
                </p>
              </el-col>
              <el-col :span="8">
                <p>
                  <i class="el-icon-pear"></i>
                  {{jobStatus}}
                </p>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="8">
                <p>
                  <i class="el-icon-message"></i>
                  邮箱：llqhi@qq.com
                </p>
              </el-col>
              <el-col :span="8">
                <p>
                  <i class="el-icon-mobile-phone"></i>
                  电话：15701655950
                </p>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6" style="text-align: right; padding: 10px 10px 0px 0px;">
            <img
              src="../assets/me-head-2.jpg"
              alt
              width="128px"
              style="border-radius: 10px; filter: brightness(1.2);"
            />
          </el-col>
        </el-row>
      </div>

      <div class="section border-bottom">
        <el-row>
          <h3>我的优势</h3>
        </el-row>
        <el-row type="flex">
          <template v-if="Array.isArray(selfIntro)">
            <ul>
              <li v-for="(li, i) in selfIntro" :key="i">
                <span style="color: #999">{{i+1}}.</span>
                {{li}}
              </li>
            </ul>
          </template>
          <template v-else>
            <div>{{selfIntro}}</div>
          </template>
        </el-row>
      </div>

      <div class="section border-bottom">
        <el-row>
          <h3>求职意向</h3>
        </el-row>
        <template v-for="job in jobs">
          <el-row type="flex" :key="job.title" style="height: 2rem;">
            <el-col :span="8">{{job.title}}</el-col>
            <el-col :span="8">{{job.salary}}</el-col>
            <el-col :span="8">{{job.location}}</el-col>
          </el-row>
        </template>

        <!-- <el-row type="flex">
          <el-col :span="8">golang 游戏服务器开发</el-col>
          <el-col :span="8">20k ～ 25k</el-col>
          <el-col :span="8">游戏 - 北京</el-col>
        </el-row>-->
      </div>

      <div class="section border-bottom">
        <el-row>
          <h3>工作履历</h3>
        </el-row>
        <el-row type="flex">
          <el-timeline :reverse="true">
            <template v-for="(item, index) in workList">
              <el-timeline-item :timestamp="item.time" placement="top" :key="index">
                <div style="margin-bottom: 20px;">
                  <h4>{{item.company}}</h4>
                  <p>
                    <span style="color: #aaa">职位：</span>
                    {{item.career}}
                  </p>
                  <p>
                    <span style="color: #aaa">工作内容：</span>
                    <template v-if="Array.isArray(item.content)">
                      <ul>
                        <li v-for="(li, i) in item.content" :key="i">
                          <span style="color: #999">{{i+1}}.</span>
                          {{li}}
                        </li>
                      </ul>
                    </template>
                    <template v-else>
                      <div>{{item.content}}</div>
                    </template>
                  </p>
                  <p>
                    <span style="color: #aaa">工作业绩：</span>
                    <template v-if="Array.isArray(item.performance)">
                      <ul>
                        <li v-for="(li, i) in item.performance" :key="i">
                          <span style="color: #999">{{i+1}}.</span>
                          {{li}}
                        </li>
                      </ul>
                    </template>
                    <template v-else>
                      <div>{{item.performance}}</div>
                    </template>
                  </p>
                  <el-tag
                    size="mini"
                    v-for="(tag, i) in item.tags"
                    :key="i"
                    style="margin-right: 4px;"
                  >{{tag}}</el-tag>
                </div>
              </el-timeline-item>
            </template>
          </el-timeline>
        </el-row>
      </div>

      <div class="section border-bottom">
        <el-row>
          <h3>教育经历</h3>
        </el-row>
        <el-row type="flex" justify="space-between">
          <el-col :span="12" style="text-align: left">
            <p style="margin-top: 0px;">长治学院</p>
            <p>计算机科学与技术 - 本科</p>
          </el-col>
          <el-col :span="12" style="text-align: right">2011 ～ 2015</el-col>
        </el-row>
      </div>

      <div class="section border-bottom">
        <el-row>
          <h3>最近使用的技术</h3>
        </el-row>
        <el-row type="flex">
          <el-tag
            size="mini"
            type="info"
            v-for="(item, index) in lastTech"
            :key="index"
            style="margin-right: 4px;"
          >{{item}}</el-tag>
        </el-row>
      </div>

      <div class="section">
        <el-row>
          <h3>个人作品</h3>
        </el-row>
        <el-row type="flex">
          <el-col
            :span="6"
            v-for="(item, index) in products"
            :key="index"
            style="text-align: center;"
            class="my-product"
          >
            <el-row type="flex" align="middle" justify="space-around" style="padding: 4px 0px;">
              <img :src="item.img" style="width: 100px; height: 100px;" alt />
            </el-row>
            <el-row type="flex" align="middle" justify="space-around" style="padding: 4px 0px;">
              <a :href="item.href">{{item.title}}</a>
            </el-row>
          </el-col>
        </el-row>
      </div>

      <div class="section">
        <el-row>
          <h3>相关链接</h3>
        </el-row>
        <el-row type="flex">
          <el-col :span="18">
            <el-row
              type="flex"
              align="middle"
              justify="start"
              style="padding: 4px 0px;"
              v-for="(item, index) in links"
              :key="index"
            >
              <el-col :span="4">
                <i :class="item.iconClass" style="padding-right: 4px; color: #666"></i>
                <a :href="item.href">{{item.name}}</a>
              </el-col>
              <el-col :span="8">
                <small style="padding: 0px 10px; font-size: 0.7rem">{{item.desc}}</small>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6" style="text-align: right;">
            <img width="64px" style="opacity: 0.35;" src="../assets/badfox.me.png" alt srcset />
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "../store";
import moment from "moment";
export default {
  data: () => {
    return {
      // 在职状态
      jobStatus: "在职",
      // 工作履历
      workList: [
        {
          time: "2013-09 ～ 2015-09",
          company: "长治市朋克科技有限公司",
          career: "JavaWeb 开发",
          content: [
            "在校期间创业成立公司，主要是O2O购物网站，我负责整个网站的后端开发",
            "使用 Java web 的「ssh」三大框架",
            "独立完成了整个一套网站的系统开发"
          ],
          performance: [
            "几个小伙伴的创业，起于一个点子和一颗学以致用的心",
            "我们自学 java web，有负责页面，有负责线下。我主要负责后端代码和项目部署",
            "我们的目的是创业成功，同时也学以致用，最后也搞出了一些影响力",
            "有时候目光看不远，是限于经历;有时候什么都没有，更敢于拼搏闯荡"
          ],
          tags: ["java", "后端开发", "web前端", "linux"]
        },
        {
          time: "2015-09 ～ 2016-03",
          company: "北京甲壳虫科技有限公司",
          career: "h5 游戏开发",
          content: [
            "和朋友一起二次创业，开发 H5 游戏「七雄世界」",
            "使用 canvas 自研引擎开发，前后端都使用 javascript",
            "主要负责部分系统的前后端开发"
          ],
          performance: [
            "从零开始学习 nodejs开发和H5游戏制作",
            "使用自主框架，了解游戏原理和 canvas 绘图机制",
            "能独立负责模块的开发工作，并在工作之外分享一些自己的点子",
            "学习 cocos2d，cocos creator",
            "学习 pomelo"
          ],
          tags: ["NodeJS", "HTML5", "cocos creator"]
        },
        {
          time: "2016-03 ～ 2018-03",
          company: "iHealth 小米生态链",
          career: "NodeJS 全栈工程师",
          content: [
            "react redux 从入门到精通，实现管理系统的编写",
            "学习 JavaScript es7 语法，并应用到后端框架",
            "负责后段接口逻辑实现，数据库设计",
            "负责web端管理平台的 react代码编写",
            "负责Linux服务器部署",
            "负责新技术调研"
          ],
          performance: [
            "使用第三方 im 聊天系统，独立制作 react web 聊天模块",
            "能够独立负责项目部分功能模块开发工作",
            // "解决过一次，因Linux服务器redis弱密码，导致的注入，被挖矿的问题",
            // "了解区块链原理",
            "自学 go 语言"
          ],
          tags: ["reactjs", "vuejs", "mongoDB", "Redis"]
        },
        {
          time: "2018-03 ～ 2019-05",
          company: "北京尼比鲁科技有限公司（Tap4Fun）",
          career: "golang 服务器开发",
          content: [
            "根据策划的游戏方案，负责 SLG 服务器新功能的开发",
            "负责配合前端（unity）处理一些游戏 bug",
            "根据业务需求学习 erlang，配置老项目活动功能",
            "负责游戏活动的服务器配合，以及项目部署"
          ],
          performance: [
            "加速道具 & 钻石一键加速功能",
            "FPS 特工系统（特工，枪械，装备，升级，特工切换，枪支切换等）",
            "VIP 系统（领奖系统，VIP升级，VIP第二建筑队列）",
            "每日领奖签到功能",
            "集结撤军（集结出发前的撤军：包括路上撤军和到达撤军）",
            "任务系统的调整（增加新的任务，登录任务完成校验）",
            "搭建内网下载安装 ios 的 ipa 安装包环境",
            "gm web 页面，命令智能提示功能，gm 文档生成工具"
          ],
          tags: ["golang", "erlang", "python", "beego", "unity3D"]
        },
        {
          time: "2019-08 ～ 至今",
          company: "北京三个逗号科技有限公司（音遇）",
          career: "golang 服务器开发",
          content: ["主要负责服务器逻辑开发"],
          performance: ["排行榜系统", "抽奖系统"],
          tags: ["golang", "python", "redis", "rabbitmq"]
        }
      ],
      // 最近使用的技术
      lastTech: [
        "微信小程序开发",
        "微信小游戏开发",
        "golang",
        "cocos creator",
        "unity3D",
        "nodejs",
        "vuejs"
      ],
      // 自我介绍
      selfIntro: [
        "学习能力强，能够迅速适应新环境",
        "熟练使用 js es6 es7、cocos creator，有完整的小游戏开发经验，有独立制作的上线微信小游戏",
        "有 unity3d 使用经验，对 cocos creator 的数据驱动开发很熟悉",
        "熟练使用 cocos creator 中的 ui 组件，prefab，animation，以及组件自定义，事件编写，微信子域排行榜。使用过 shader",
        "从 cocos creator 2016年发布开始就使用了，踩过一些坑",
        "喜欢钻研新技术，喜欢解决技术问题，属于自我驱动型"
      ],
      // 期望工作
      jobs: [
        { title: "cocos creator 开发", salary: "可面谈", location: "北京" }
        // { title: "服务器开发", salary: "20 ～ 25k（可谈）", location: "北京" },
        // { title: "NodeJS开发", salary: "20 ～ 25k（可谈）", location: "北京" }
        // { title: "Golang开发", salary: "20 ～ 25k（可谈）", location: "北京" }
      ],
      // 个人作品
      products: [
        {
          title: "微信小游戏「狂浪战舰」",
          img: require("../assets/aircraft.jpg")
        },
        {
          title: "微信小程序「GI查询」",
          img: require("../assets/gi-qrcode.jpg")
        }
      ],
      // 相关链接
      links: [
        {
          iconClass: "bf bf-logo-badfox",
          href: "https://badfox.me",
          name: "badfox.me",
          desc: "个人网站"
        },
        {
          iconClass: "bc bc-github-fill",
          href: "https://github.com/llqgit",
          name: "llqgit",
          desc: "github"
        },
        {
          iconClass: "el-icon-s-management",
          href: "https://www.jianshu.com/u/8e831b597f9f",
          name: "简书",
          desc: "文库"
        }
      ]
    };
  },
  computed: {
    workAge() {
      return moment("2015-10-01")
        .locale("zh_cn")
        .toNow(true);
    }
  },
  mounted() {
    // 切换主题
    store.setTheme("light");
  }
};
</script>

<style lang="less" slot-scope>
.resume-content {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0px 10px;
  margin-bottom: 50px;
}
.section {
  padding: 10px 0px 20px 0px;
  // margin-bottom: 20px;
}
.border-bottom {
  // padding-bottom: 10px;
  border-bottom: 1px solid rgba(221, 221, 221, 0.5);
}
li {
  list-style-type: none;
  padding: 2px 0px;
}
.my-product {
  border: 1px solid #fff0;
  border-radius: 10px;
  transition: all 0.1s;
  &:hover {
    border: 1px solid #0090ce;
  }
}
</style>
